App.CurrentUser = class CurrentUser {
  constructor(options){
    this.lists = {}
    this.following = []
    this.liked_photos = []

    Object.keys(options).forEach(key => {
      if(this.allowedKeys.includes(key)){
        this[key] = options[key];
      }
    })

    App.ready(()=>{
      this.setup();
    })
  }

  get allowedKeys() {
    return ["id", "state", "invite_token", "user_type", "campaigns", "interests", "lists", "liked_photos", "followers", "following", "unread_notification_count", "avatar_url", "cover_photo_url", "location"]
  }

  setup () {
    if (!(this.id != undefined)){
      this.set_invite_token(cookies.get('invite_token'));
      this.set_membership_cta();
      this.preview_links();
    } else {
      this.set_invite_token(this.invite_token);
      this.update_bookmarks();
      this.update_liked_photos();
      this.update_following();
      this.show_user_methods();
      this.update_reviews();
      window['update_bookmarks'] = this.update_bookmarks;
    }

    if (window['Events'] != undefined) {
      Events.dispatch(document, 'userReady');
    }
  }

  static ready (callback){
    Events.add(document,'userReady', function(e){
      callback();
    })
  }

  set_invite_token (token){
    document.querySelector('meta[property="outbound:invite_token"]').content = token;
  }

  set_membership_cta () {
    if (this.user_type == undefined || !(this.user_type.match('Member'))){
      document.querySelectorAll('#membership_cta').forEach(e => e.classList.remove('hidden'));
    }
  }

  preview_links () {
    document.querySelectorAll('[data-preview=false]').forEach(el => {
      el.href = '/users/sign_up';
      Events.add(el, 'click', (e)=> {
        el.dataset["url"] = '/users/sign_up';
        el.dataset['modal'] = true;
        Modal.load(e.target.dataset);
        e.preventDefault();
      });
    });
  }

  update_bookmarks () {
    const lists = !!(this !== undefined && this.lists) ? this.lists : current_user.lists
    Object.keys(lists||{}).forEach((list_id) => {
      lists[list_id].list_items.forEach((item) => {
        document.querySelectorAll('[data-resource-id="'+item+'"] .btn-save-bookmark, .btn-save-bookmark[data-resource-id="'+item+'"]').forEach((action) => {
          action.classList.add('active');
          const text = action.querySelector('span[data-bookmark-count]')
          if(text){
            text.inner_html = "Saved";
          }
        })
      })
    })
  }

  update_following () {
    //Updates following buttons with state based on who the user follows
    this.following.forEach((ii, f) =>{
      document.querySelectorAll('.follow[data-resource-id="'+f+'"]').forEach(el => {
        el.classList.add('active');
        el.querySelector('.follow[data-resource-id="'+f+'"]').inner_html = 'Following';
      })
    })
  }

  update_liked_photos () {
    this.liked_photos.forEach((f) => {
      document.querySelectorAll('.like[data-resource-id="'+f+'"]').forEach(el => {
        el.classList.add('active');
        el.innerHTML = 'Liked';
      })
    })
  }

  show_user_methods () {
    document.querySelectorAll(".hidden[data-author-id='"+this.id+"'],[data-author-id]:not([data-user-id='"+this.id+"'])").forEach(el => {
      el.classList.contains('hidden') ? el.classList.remove('hidden') : el.classList.add('hidden')
    })
    if (this.state == 'super') {
      document.querySelectorAll(".hidden.admin-only, .hidden.member-only").forEach(el => {
        el.classList.remove('hidden');
      })
    }
  }

  update_reviews () {
    document.querySelectorAll(".review .actions[data-author-id='"+this.id+"']").forEach(el => {
      el.innerHTML = el.innerHTML + "<a data-url='/reviews/"+el.dataset['data-review-id']+"/edit' data-modal='true' data-modal-type='bookmark' rel='nofollow' href='#'>Edit</a> | <a rel='nofollow' data-remote='true' data-method='delete' href='/reviews/"+el.dataset['data-review-id']+".js'>Delete</a>";
    })
  }

  set_location_path (){
    this.location.location_path = "/"
    const states = {"AK":"Alaska", "AL":"Alabama", "AR":"Arkansas", "AZ":"Arizona", "CA":"California", "CO":"Colorado", "CT":"Connecticut", "DC":"District of Columbia", "DE":"Delaware", "FL":"Florida", "GA":"Georgia", "HI":"Hawaii", "IA":"Iowa", "ID":"Idaho", "IL":"Illinois", "IN":"Indiana", "KS":"Kansas", "KY":"Kentucky", "LA":"Louisiana", "MA":"Massachusetts", "MD":"Maryland", "ME":"Maine", "MI":"Michigan", "MN":"Minnesota", "MO":"Missouri", "MS":"Mississippi", "MT":"Montana", "NC":"North Carolina", "ND":"North Dakota", "NE":"Nebraska", "NH":"New Hampshire", "NJ":"New Jersey", "NM":"New Mexico", "NV":"Nevada", "NY":"New York", "OH":"Ohio", "OK":"Oklahoma", "OR":"Oregon", "PA":"Pennsylvania", "RI":"Rhode Island", "SC":"South Carolina", "SD":"South Dakota", "TN":"Tennessee", "TX":"Texas", "UT":"Utah", "VA":"Virginia", "VT":"Vermont", "WA":"Washington", "WI":"Wisconsin", "WV":"West Virginia", "WY":"Wyoming"};
    let region = this.location.region;
    if (this.location.country_code == "US"){
      if (states[this.location.region] != undefined){
        region = states[this.location.region];
      }  
      this.location.location_path = "/" + [this.location.country.slugify(), region.slugify(), this.location.city.slugify()].clean().join("/");
    } else {
      this.location.location_path = "/" + [this.location.country.slugify(), this.location.city.slugify()].clean().join("/");
    }
  }

  filter_content (){
    document.querySelectorAll('[data-user-id][data-state]:not([data-user-id="'+this.id+'"])').forEach(el => {
      if(['draft', 'disabled', 'pending', 'rejected'].includes(el.dataset.state))
        el.remove();
    })
  }

  owns (selector = 'body', keys=[]){
    const elements = document.querySelectorAll(selector)
    return Array.from(elements).map((e) => {
      const truthy = Object.entries(e.dataset).reduce((a, [k,v]) => {
        if(keys.includes(k.toString())) { a.push(e); }; return a; 
      }, [])
      return truthy ? e : null;
    })
  }
}
